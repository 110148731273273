// アンケート設問一覧
export const questionnaires: { question: string; answers: string[] }[] = [
  {
    question: 'このキャンペーンをどこで知りましたか。',
    answers: [
      '店頭にて',
      'TV-CM',
      'ホームページ',
      'SNS',
      '知人に聞いた',
      'その他',
    ],
  },
  {
    question: 'このキャンペーンについてご感想をお聞かせください。',
    answers: [
      '大変良い',
      '良い',
      'やや良くない',
      '良くない',
      'その他',
    ],
  },
  {
    question: '今後どのようなイベントがあれば参加したいですか。',
    answers: [],
  },
  // {
  //   question: '各ショップの11周年特別クーポンはご利用されましたか？',
  //   answers: ['YES', 'NO'],
  // },
];
